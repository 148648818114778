import React, { useState } from 'react'
import { Header } from './Sidebar';
import { Topbar } from './Topbar';

export const Main = (props) => {
    const [sidebarProps, setSidebarProps] = useState({});
    const updateSidebarProps = (props) => {
        setSidebarProps(props);
    };
    return (
        <Header route={props.route} {...sidebarProps}>
            {() => (
                <div className="inner-content">
                    {/* <!-- Topbar --> */}
                    <Topbar updateSidebarProps={updateSidebarProps} />
                    {props.children()}
                </div>
            )}
        </Header>
    )
}
