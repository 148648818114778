import React, { useState } from 'react'
import { Main } from './Main'
import { toast } from 'react-toastify'
import userService from '../Services/user.service'
import { useSelector } from 'react-redux'

export const Wordpress = () => {
    const [changeInput, setInputChange] = useState(false)
    const [flag, setflag] = useState(false)
    const [errors, setErrors] = useState({})
    const user = useSelector((state) => state?.auth?.user?.data)
    let wordpressUserId = localStorage.getItem(`wordpress ${user?._id}`)
    const [formFields, setFormFields] = useState({
        url : wordpressUserId !== 'undefined' ? JSON.parse(wordpressUserId)?.url ?? '' : '',
        username : wordpressUserId !== 'undefined' ? JSON.parse(wordpressUserId)?.username ?? '' : '',
        password: '',
    })
    const handleInputChange = (e) => {
        setInputChange(true)
        setFormFields((state) => ({
            ...state,
            [e.target.name] : e.target.value
        }))
    }

    const wordpressCredentials = (e) => {
        e.preventDefault()
        setflag(true)
        userService.wordpressCredentials(formFields).then((data) => {
            if (data.response) {
                if (Object.keys(data.response.data.errors).length) {
                    setErrors(data.response.data.errors)
                }
            } else {
                toast.success(data.message)
                setErrors({})
                localStorage.setItem('wordpress ' + user._id, JSON.stringify(formFields))
            }
            setflag(false)
        }).catch((err) => {
            setErrors(err.response.data.errors)
            setflag(false)
        })
    }

    return (
        <Main route="integration">
            {() => (
                <div className="content-box">
                    <div className="articles-btn-wrapper p-4">
                        <form action="">
                            <div className='col-md-12 col-lg-12 col-xl-12'>
                                <div className="form-outline mb-4 title-input-box">
                                    <label className="form-label">URL</label>
                                    <input type="text" id="url" name='url' className="form-control"
                                    onChange={(e) => {handleInputChange(e)}}
                                    value={formFields.url}
                                    placeholder="Enter url" />
                                    <p className='text-danger'>{errors?.['url']}</p>
                                </div>
                                <div className="form-outline mb-4 title-input-box">
                                    <label className="form-label">Username</label>
                                    <input type="text" id="username" name='username' className="form-control"
                                    onChange={(e) => {handleInputChange(e)}}
                                    value={formFields.username}
                                    placeholder="Enter username" />
                                    <p className='text-danger'>{errors?.['username']}</p>
                                </div>
                                <div className="form-outline mb-4 title-input-box">
                                    <label className="form-label">Password</label>
                                    <input type="password" id="password" name='password' className="form-control"
                                    onChange={(e) => {handleInputChange(e)}}
                                    value={formFields.password}
                                    placeholder="Enter password" />
                                    <p className='text-danger'>{errors?.['password']}</p>
                                </div>
                            </div>
                            {/* <button className='btn btn-primary' onClick={(e) => wordpressCredentials(e)}>Save</button> */}
                            {flag ? (
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                </button>
                            ) : <button type="button" onClick={(e) => wordpressCredentials(e)} className={`btn btn-primary${!changeInput ? ' disabled' : ''}`}>
                                    Save
                                </button>
                            }
                        </form>
                    </div>
                </div>
            )}
        </Main>
    )
}
