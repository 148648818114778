import axios from "axios"
import { BASE_URL, NODE_BASE_URL } from "../Constant"
import authHeader from "./auth-header"

const createSingleArticle = async (data) => {
    try {
        let result = await axios.post(NODE_BASE_URL + "article_generate", data, { headers: authHeader() })
        return result.data
    } catch (error) {
        return error
    }
}

const createMultipleArticles = async (data) => {
    try {
        let result = await axios.post(NODE_BASE_URL + "multiple_article_generate", data, { headers: authHeader() })
        return result.data
    } catch (error) {
        return error
    }
}

const getDocs = async (type, currentPage, text) => {
    try {
        let result = await axios.get(
                BASE_URL + `get-docs?type=${type}&page=${currentPage}&value=${text}`,
                { headers: authHeader() }
            )
        return result.data
    } catch (error) {
        return error
    }
}

const getSubArticles = async (type, currentPage, text, slug) => {
    try {
        let result = await axios.get(
                BASE_URL + `get-sub-articles?type=${type}&page=${currentPage}&search=${text}&slug=${slug}`,
                { headers: authHeader() }
            )
        return result.data
    } catch (error) {
        return error
    }
}

const titleUpdate = async (data) => {
    try {
        let result = await axios.post(BASE_URL + 'title-update', data, { headers : authHeader() })
        return result.data
    } catch (error) {
        return error
    }
}

const deleteData = async (data) => {
    try {
        let result = await axios.post(BASE_URL + 'delete-post', data, { headers : authHeader() })
        return result.data
    } catch (error) {
        return error
    }
}

const rewriteArticle = async (data) => {
    try {
        let result = await axios.post(NODE_BASE_URL + 'rewrite_article', data, { headers : authHeader() })
        return result.data
    } catch (error) {
        return error
    }
}

const bulkDelete = async (data) => {
    try {
        let result = await axios.post(BASE_URL + 'bulk-delete', data, { headers : authHeader() })
        return result.data
    } catch (error) {
        return error
    }
}

const postToWordpress = async (data) => {
    try {
        let result = await axios.post(NODE_BASE_URL + 'post_to_wordpress', data, { headers : authHeader() })
        return result.data
    } catch (error) {
        return error
    }
}

const obj = {
    createSingleArticle,
    createMultipleArticles,
    getDocs,
    getSubArticles,
    titleUpdate,
    deleteData,
    rewriteArticle,
    bulkDelete,
    postToWordpress
}

export default obj