import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css'
import './assets/css/tagify.css'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Provider } from "react-redux";
import store from "./Components/store";
import { LOGIN_FAIL, LOGIN_SUCCESS, UNATUHORIZED } from './Components/actions/types';
import userService from './Services/user.service';
import './assets/css/responsive.css'
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: '10e597d229673e73e60d',
//     cluster: 'ap2',
//     encrypted: true,
// });
// store.dispatch(getData()).then((data) => {
//   console.log(data, 'indexjs');
// }).catch((err) => {
//   console.log(err, 'errindexjs');
// });
try {
  let data = await userService.getData()
  if (data) {
    if (data?.response?.statusText === "Unauthorized") {
        store.dispatch({
          type : UNATUHORIZED,  
          payload : data?.response?.statusText
      })
    } else if (!data.status) {
        store.dispatch({
          type : LOGIN_FAIL,
          payload : { data }
        })
    } else {
      store.dispatch({
        type: LOGIN_SUCCESS,
        payload: { data },
      });
    }
  } else {
    store.dispatch({
        type : LOGIN_FAIL,
        payload : { data : false }
    })
  }
} catch (error) {
  
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <Provider store={store}>
  //     <App />
  //     <AuthProvider store={store}>
  //       <RouterProvider router={router} />
  //     </AuthProvider>
  //   </Provider>
  //   <ToastContainer />
  // </React.StrictMode>
  <main className='main'>
    <div className='content-wraper'>
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <ToastContainer />
        </Provider>
      </React.StrictMode>
    </div>
  </main>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
