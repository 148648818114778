import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import articleService from '../Services/article.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const CustomModal = ({setShow, show, data}) => {
    const [btnDisabled, setBtnDisabled] = useState(false)
    const navigate = useNavigate()
    const handleClose = () => setShow(false);
    const btnClick = async (data) => {
        setBtnDisabled(true)
        if (data.type === 'all') {
            await postData()
        } else {
            let postData = {
                id : data.parentId,
                type : data.type,
                subArticleId : data.id
            }
            await articleService.deleteData(postData).then((res) => {
                setBtnDisabled(false)
                if (data.type !== 'project') {
                    data.setCurrentPage(1)
                    data.setDocuments([])
                    data.setIsLoading(false)
                    data.setTotalPages(1)
                    data?.setReload(!data?.reload)
                    data?.setArticle('')
                } else {
                    navigate('/bulk_article_list', { replace : true })
                }
                setShow(false);
                toast.success(res?.message)
            }).catch((err) => {
                setBtnDisabled(false)
                setShow(false);
                console.log(err);
            })
        }
    }

    const postData = async () => {
        await articleService.postToWordpress(data).then((res) => {
            console.log(res);
            setBtnDisabled(false)
            if (res?.code === 200) {
                toast.success(res?.message)
            } else {
                toast.error(res?.message)
            }
            setShow(false);
        }).catch((err) => {
            setBtnDisabled(false)
            setShow(false);
            console.log(err);
        })
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{data?.type === 'all' ? 'Post articles to wordpress' : 'Delete'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{data?.type === 'all' ? data?.modalBody : 'Are you sure you want to delete this?' }</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    Close
                    </Button>
                    <Button variant="danger" className={btnDisabled ? 'disabled' : ''} onClick={() => btnClick(data)}>
                    Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}