import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from './actions/auth';
import { toast } from 'react-toastify';
import { Main } from './Main';

export const Profile = () => {
  const [errors, setErrors] = useState({})
  const [image, setImage] = useState('')
  const [changeInput, setInputChange] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector((state) => state?.auth?.user?.data)
  const { profileLoading } = useSelector((state) => state?.auth)
  const [formFields, setFormFields] = useState({
    first_name : user?.first_name,
    last_name: user?.last_name,
    phone : user?.phone,
    image : ''
  })
  const handleInputChange = (e) => {
    setInputChange(true)
    if (e.target.name === 'image') {
      setImage(e.target.files[0])
    }
    setFormFields((state) => ({
        ...state,
        [e.target.name] : e.target.value
    }))
  }
  const profileUpdate = (e) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('first_name', formFields.first_name)
    formData.append('last_name', formFields.last_name)
    formData.append('phone', formFields.phone)
    formData.append('image', image)
    try {
      dispatch(updateUser(formData)).then((data) => {
          if (data.response) {
            setErrors(data.response.data.errors)
          } else {
            toast.success(data.message)
            setErrors({})
            setImage('')
          }
      })
    } catch (error) {
        console.log(error, 'err');
    }
  }
  return (
    <Main route="profile">
      {() => (
          <div className="container-fluid h-custom">
            <form onSubmit={(e) => {profileUpdate(e)}} className="row col-md-8 article-input-box mt-4" encType="multipart/form-data">
              <div className='col-md-6 col-lg-6 col-xl-6'>
                <div className="form-outline mb-4 title-input-box">
                    <label className="form-label">First Name</label>
                    <input type="text" id="first_name" name='first_name' className="form-control"
                    onChange={(e) => {handleInputChange(e)}}
                    value={formFields.first_name}
                    placeholder="Enter a valid first name" />
                    <p className='text-danger'>{errors?.['first_name']}</p>
                </div>
                <div className="form-outline mb-4 title-input-box">
                    <label className="form-label">Last Name</label>
                    <input type="text" id="last_name" name='last_name' className="form-control"
                    onChange={(e) => {handleInputChange(e)}}
                    value={formFields.last_name}
                    placeholder="Enter a valid last name" />
                    <p className='text-danger'>{errors?.['last_name']}</p>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-6'>
                <div className="form-outline mb-4 title-input-box">
                    <label className="form-label">Phone</label>
                    <input type="text" id="phone" name='phone' className="form-control"
                    onChange={(e) => {handleInputChange(e)}}
                    value={formFields.phone}
                    placeholder="Enter a valid phone" />
                    <p className='text-danger'>{errors?.['phone']}</p>
                </div>
                <div className="form-outline mb-3 title-input-box">
                    <label className="form-label">Profile Image</label>
                    <input type="file" id="image" name='image' className="form-control"
                    onChange={(e) => {handleInputChange(e)}}
                    value={formFields.image} />
                    <p className='text-danger'>{errors['image']}</p>
                </div>
              </div>
              <div className="form-outline">
                {!profileLoading ? 
                      <button type="submit" className={`btn btn-primary${!changeInput ? ' disabled' : ''}`}
                      >Update Profile</button>
                  :
                      <div className="spinner-border text-primary" role="status">
                          <span className="sr-only"></span>
                      </div>
                  }
              </div>
            </form>
          </div>
      )}
    </Main>
  )
}
