import React from 'react'
import pencil from '../assets/img/pencil.svg'
import paper from '../assets/img/paper.svg'
import note from '../assets/img/note.svg'
import { useNavigate } from 'react-router-dom'
import { Main } from './Main'

export const Dashboard = () => {
    const navigate = useNavigate()
    return (
        <Main route="dashboard">
            {() => (
                <div className="content-box">
                    <div className="articles-btn-wraper">
                        <div className="row flex-column">
                            <div className="col-md-9 col-lg-8 col-xl-6 mx-auto">
                                <a onClick={() => navigate('/single_article')} className="article-page-btn cursor-pointer">
                                    <div className="text-box">
                                        <span className="tag">AI Content</span>
                                        <h2>Single Article Writer</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        <div className="icon-box">
                                            <img src={pencil} alt="" />
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-9 col-lg-8 col-xl-6 mx-auto">
                                <a onClick={() => navigate('/bulk_article')} className="article-page-btn cursor-pointer">
                                    <div className="text-box">
                                        <span className="tag">AI Content</span>
                                        <h2>Bulk Article Writer</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        <div className="icon-box">
                                            <img src={paper} alt="" />
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-9 col-lg-8 col-xl-6 mx-auto">
                                <a href="#" className="article-page-btn">
                                    <div className="text-box">
                                        <span className="tag">AI Content</span>
                                        <h2>Template</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                        <div className="icon-box">
                                            <img src={note} alt="" />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Main>
    )
}
