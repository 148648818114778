import React, { useState } from 'react'
import articleService from '../Services/article.service'
import { useSelector } from 'react-redux'
import iconHtml from '../assets/img/icon-html.svg'
import iconCopy from '../assets/img/icon-copy.svg'
import iconGenerate from '../assets/img/icon-generate.svg'
import { Select } from './Select';
import Moment from 'moment';
import { Tooltip } from 'react-tooltip';
import { Main } from './Main';

export const SingleArticle = () => {
    const [article, setArticle] = useState('')
    const [error, setError] = useState('')
    const [input, setInput] = useState({
        language : '0',
        tone : '0',
        wordCount : '0',
        projectType : '0',
        inputVal : ''
    })
    const [inputErrors, setInputErrors] = useState({
        language : '',
        tone : '',
        wordCount : '',
        projectType : '',
        inputVal : ''
    })
    const [flag, setFlag] = useState(false)
    const [sourceFlag, setSourceFlag] = useState(false)
    const [isCopied, setIsCopied] = useState(false);
    const user = useSelector((state) => state?.auth?.user?.data)
    const [show, setShow] = useState(false)
    const [mode, setMode] = useState('Article keywords')
    const [subheadings, setSubheadings] = useState([])
    const [headingVal, setHeadingVal] = useState('')
    const [includeUrl, setIncludeUrl] = useState(false)

    let toneOpt = [
        { value : 'Formal', label : 'Formal' },
        { value : 'Informal', label : 'Informal' },
        { value : 'Funny', label : 'Funny' },
    ]

    let languageOpt = [
        { value : 'English', label : 'English' },
        { value : 'French', label : 'French' },
        { value : 'Spanish', label : 'Spanish' },
    ]
    
    let wordCountOpt = [
        { value : '500', label : '500' },
        { value : '1000', label : '1000' },
        { value : '1500+', label : '1500+' },
    ]

    let projectTypeOpt = [
        { value : 'How to', label : 'How to' },
        { value : 'Reviews', label : 'Reviews' },
        { value : 'Top best', label : 'Top best' },
        { value : 'Alternatives', label : 'Alternatives' },
        { value : 'Versus', label : 'Versus' },
        { value : 'Others', label : 'Others' },
    ]

    const copyTextToClipboard = async (text) => {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(text);
        } else {
          return document.execCommand('copy', true, text);
        }
    }
    const sendData = (e) => {
        e.preventDefault()
        const allErrors = {}
        for (const key in input) {
            if (!input[key].trim() || input[key] === '0') {
                if (key === 'inputVal') {
                    allErrors[key] = `Please enter a project name.`;
                } else {
                    allErrors[key] = `Please enter a value of ${key}.`;
                }
            } else {
                allErrors[key] = '';
            }
        }
        setInputErrors(allErrors)
        if (Object.values(allErrors).every((val) => !val)) {
            setFlag(true)
            setShow(false)
            let data = {
                keyword : input.inputVal,
                projectType : input.projectType,
                user_id : user?._id,
                headings : subheadings,
                language : input.language,
                tone : input.tone,
                wordCount : input.wordCount,
                includeUrl : includeUrl
            }
            articleService.createSingleArticle(data).then((res) => {
                if (res?.response?.data?.error?.error) {
                    setError(res?.response?.data?.error?.error.message)
                } else if (res?.response?.status == 400) {
                    setError(res?.response?.data?.error)
                } else if (res?.name == 'AxiosError') {
                    setError(res?.message)
                } else {
                    setArticle(res?.article)
                    setShow(true)
                }
                setInput({
                    language : '0',
                    tone : '0',
                    wordCount : '0',
                    projectType : '0',
                    inputVal : ''
                })
                setSubheadings([])
                setFlag(false)
            }).catch((err) => {
                console.log(err, 'err');
            })
        }
    }
    const source = () => {
        setSourceFlag(!sourceFlag)
    }
    const CopyData = (e) => {
        e.preventDefault()
        copyTextToClipboard(article?.body)
        .then(() => {
            setIsCopied(true);
            setTimeout(() => {
            setIsCopied(false);
            }, 1500);
        })
        .catch((err) => {
            console.log(err);
        });
    }
    const onChangeGenerateMode = (val) => {
        setMode(val)
    }
    const addSubheadings = () => {
        if (headingVal !== '') {
            setSubheadings([...subheadings, headingVal])
        }
        setHeadingVal('')
    }

    const closeErrorDiv = () => {
        setError('')
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInput(values => ({...values, [name]: value}))
    }

    const rewriteArticle = async (e) => {
        e.preventDefault()
        setFlag(true)
        setShow(false)
        let articleObj = {}
        articleService.rewriteArticle({article, articleObj}).then((res) => {
            if (res?.response?.data?.error?.error) {
                setError(res?.response?.data?.error?.error.message)
            } else {
                setArticle(res)
                setShow(true)
            }
            setFlag(false)
        }).catch((err) => {
            console.log(err, 'err');
            setFlag(false)
        })
    }

    return (
        <Main route="single_article">
            {() => (
                <div className="row mt-4">
                    <div className="col-lg-5 col-xxl-4">
                        <div className="article-left-side">
                            <div className="article-input-detail article-input-box">
                                <div className="sec-title-box">
                                    <h2>Input Detail</h2>
                                    <a className="redamore-btn cursor-pointer">Learn More</a>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Select options={languageOpt} setFunc={handleChange} name='language' value={input.language} firstOpt="Language" />
                                        {inputErrors.language && <p className='mx-1 pt-2 text-danger'>{inputErrors.language}</p> }
                                    </div>
                                    <div className="col-md-6">
                                        <Select options={toneOpt} setFunc={handleChange} name='tone' value={input.tone} firstOpt="Voice of Tone" />
                                        {inputErrors.tone && <p className='mx-1 pt-2 text-danger'>{inputErrors.tone}</p> }
                                    </div>
                                    <div className="col-12 mt-3">
                                        <Select options={wordCountOpt} setFunc={handleChange} name='wordCount' value={input.wordCount} firstOpt="Word Count" />
                                        {inputErrors.wordCount && <p className='mx-1 pt-2 text-danger'>{inputErrors.wordCount}</p> }
                                    </div>
                                </div>
                            </div>
                        
                        <div className="generate-mode article-input-box">
                            <div className="sec-title-box">
                                <h2>Generate Mode</h2>
                                <a className="redamore-btn cursor-pointer">Learn More</a>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <select className="form-select article-select" onChange={(e) => onChangeGenerateMode(e.target.value)} aria-label="Article Title + Subheadings">
                                            <option value='Article keywords'>Article keywords</option>
                                            <option value='Article Title'>Article Title</option>
                                            <option value='Article Title + Subheadings'>Article Title + Subheadings</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 m-3 mb-0">
                                    <input type="checkbox" className='me-1' name="include_url" id="include_url" onChange={(e) => setIncludeUrl(e.target.checked)} />Include Url
                                </div>
                            </div>
                        </div>
                        <div className="generate-mode article-input-box">
                            <div className="sec-title-box">
                                <h2>Project Type</h2>
                                <a className="redamore-btn cursor-pointer">Learn More</a>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Select options={projectTypeOpt} setFunc={handleChange} name='projectType' value={input.projectType} firstOpt="Select Project Type" />
                                    {inputErrors.projectType && <p className='mx-1 pt-2 text-danger'>{inputErrors.projectType}</p> }
                                </div>
                            </div>
                        </div>
                        <div className="article-title article-input-box">
                            <div className="sec-title-box">
                                <h2>{mode}</h2>
                                <a className="redamore-btn cursor-pointer">Learn More</a>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <input type="text" name='inputVal' value={input.inputVal} placeholder="Everything you need to know to get better sleep" onChange={(e) => handleChange(e)} className="form-control" />
                                        {inputErrors.inputVal && <p className='mx-1 pt-2 text-danger'>{inputErrors.inputVal}</p> }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {mode === 'Article Title + Subheadings' ? (
                            <div className="subheadings article-input-box">
                                <div className="sec-title-box">
                                    <h2>Subheadings</h2>
                                    <a className="redamore-btn cursor-pointer">Learn More</a>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group d-flex">
                                            <input type="text" placeholder="Enter sub heading" className="form-control" onChange={(e) => setHeadingVal(e.target.value)} value={headingVal} />
                                            <button  className="add-heading-btn" onClick={addSubheadings}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14 6H10V2C10 1.46957 9.78929 0.960859 9.41421 0.585786C9.03914 0.210714 8.53043 0 8 0C7.46957 0 6.96086 0.210714 6.58579 0.585786C6.21071 0.960859 6 1.46957 6 2L6.071 6H2C1.46957 6 0.960859 6.21071 0.585786 6.58579C0.210714 6.96086 0 7.46957 0 8C0 8.53043 0.210714 9.03914 0.585786 9.41421C0.960859 9.78929 1.46957 10 2 10L6.071 9.929L6 14C6 14.5304 6.21071 15.0391 6.58579 15.4142C6.96086 15.7893 7.46957 16 8 16C8.53043 16 9.03914 15.7893 9.41421 15.4142C9.78929 15.0391 10 14.5304 10 14V9.929L14 10C14.5304 10 15.0391 9.78929 15.4142 9.41421C15.7893 9.03914 16 8.53043 16 8C16 7.46957 15.7893 6.96086 15.4142 6.58579C15.0391 6.21071 14.5304 6 14 6Z" fill="#5B58E6"/>
                                                </svg>                                
                                            </button>
                                        </div>
                                        <div className="scroller input-groups">
                                            {subheadings.map((data, index) => {
                                                return <div className="form-group" key={index}>
                                                    <input type="text" value={data} disabled className="form-control" />
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ''}
                        {flag ? '' :
                            <a href="single-article-writer-result.html" className="generate-btn" onClick={(e) => sendData(e)}>Generate</a>
                        }
                        </div>
                    </div>
                    <div className="col-lg-7 col-xxl-8">
                        {error !== '' ? (
                            <div className="article-right-content article-result-content">
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    {error}
                                    <button type="button" className="close alert-close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true" onClick={closeErrorDiv}>&times;</span>
                                    </button>
                                </div>
                            </div>
                        ) : ''}
                        {show ? (
                            <div className="article-right-content article-result-content">
                                {article?.imageURL !== '' ? (
                                    <div className="feature-image">
                                        <img src={article?.imageURL} alt="" className="img-fluid" />
                                    </div>
                                ) : ''}
                                <div className="article-text-box">
                                    <div className="title-wraper">
                                        <h3>{article?.title}</h3>
                                        <div className="article-btns-group">
                                            <p className="created-date">Created : {Moment(article?.created_at).format("DD, MMM yy")}</p>
                                            <button className="btn-copy" data-tooltip-id="html-tooltip" onClick={source}><img src={iconHtml} alt="" className="img-fluid" /></button>
                                            <button className="btn-copy" data-tooltip-id="copy-tooltip" onClick={(e) => CopyData(e)}><img src={iconCopy} alt=""className="img-fluid" /></button>
                                            <button className="btn-generate" data-tooltip-id="generate-tooltip" onClick={(e) => rewriteArticle(e)}><img src={iconGenerate} alt=""className="img-fluid" /></button>
                                        </div>
                                    </div>
                                    <Tooltip
                                        id="copy-tooltip"
                                        content={isCopied ? 'Copied!' : 'Copy'}
                                        events={['hover']}
                                    />
                                    <Tooltip
                                        id="generate-tooltip"
                                        content="Re-write"
                                        events={['hover']}
                                    />
                                    <Tooltip
                                        id="html-tooltip"
                                        content="source"
                                        events={['hover']}
                                    />
                                    <div className="scroller">
                                        {!sourceFlag ? <p dangerouslySetInnerHTML={{ __html: article?.body }}></p> : <p>{article?.body}</p>}
                                        {article?.metaTitle ? <p><b>Meta Title:</b> {article?.metaTitle}</p> : ''}
                                        {article?.metaDescription ? <p><b>Meta Description:</b> {article?.metaDescription}</p> : ''}
                                    </div>

                                </div>
                            </div>
                        ) : flag ? 
                            <div className="article-right-content article-result-content text-center">
                                <div>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                </div>
                            </div> : ''
                        }
                    </div>
                </div>
            )}
        </Main>
    )
}
