import React, { useEffect, useState } from 'react'
import Moment from 'moment';
import eyeIcon from '../assets/img/eye.svg'
import deleteIcon from '../assets/img/delete.svg'
import { useNavigate } from 'react-router-dom';
import articleService from '../Services/article.service';
import { CustomModal } from './CustomModal';
import { Main } from './Main';

export const BatchArticleListing = () => {
    const [loadingInitial, setLoadingInitial] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [documents, setDocuments] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [text, setText] = useState('');
    const [show, setShow] = useState(false);
    const [data, setData] = useState({});
    const [reload, setReload] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        let onChangeSearch = setTimeout( async () => {
            setLoadingInitial(true);
            await articleService.getDocs('batch', currentPage, text).then((res) => {
                setLoadingInitial(false);
                if (currentPage === 1) {
                    setDocuments(res.data.data)
                    setIsLoading(true)
                } else {
                    setDocuments([...documents, ...res.data.data])
                }
                if (loadingMore) {
                    setLoadingMore(false);
                }
                setTotalPages(res.data.last_page);
            }).catch((err) => {
                setLoadingInitial(false);
                if (loadingMore) {
                    setLoadingMore(false);
                }
                console.log(err);
            })
        }, 1000)
        return () => clearTimeout(onChangeSearch)
    }, [currentPage, text, reload])

    const loadMoreData = async () => {
        setLoadingMore(true)
        setCurrentPage(currentPage + 1);
    }

    const onChangeSearch = (e) => {
        setText(e.target.value)
        setTimeout(() => {
            setCurrentPage(1)
            setDocuments([])
            setIsLoading(false)
            setTotalPages(1)
        }, 1500)
    }

    const handleShow = (id) => {
        setShow(true)
        setData({
            parentId : id,
            type : 'batch_article',
            setCurrentPage,
            setDocuments,
            setIsLoading,
            setTotalPages,
            setReload,
            reload,
            setArticle : function () {}
        })
    }

    return (
        <Main route="batch_article_list">
            {() => (
                <div className="row mt-4">
                    <div className="col-lg-5 col-xxl-4">
                        <div className="article-left-side documents mt-3">
                            <div className="crypto-box-wraper">
                                <div className="title-wraper">
                                    <h2>My Documents</h2>
                                    <ul className="documents-tabs-links">
                                        <li>
                                        <a className='cursor-pointer' onClick={() => navigate('/single_article_list')}>Single</a>
                                        </li>
                                        <li>
                                        <a className="'cursor-pointer active">Bulk</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="bulk-article-search">
                                <h3>Bulk Article List</h3>
                                <div className="search-wraper">
                                    <form action="">
                                        <button><i className="fa-solid fa-magnifying-glass"></i></button>
                                        <input type="search" onChange={(e) => {onChangeSearch(e)}} className="form-control" placeholder="Search here..." />
                                    </form>
                                </div>
                            </div>
                            {isLoading ? 
                            documents.length ? 
                            documents.map((data, index) => {
                                return (
                                    <div className="article-input-detail article-input-box" key={index}>
                                        <div className="sec-title-box">
                                            <div className="title-with-date">
                                                <p>{Moment(data.created_at).format("DD, MMM yy")}</p>
                                                <h2>{data.title}</h2>
                                            </div>
                                            <div className="btns-group">
                                                <button onClick={() => navigate('/sub_articles/' + data.slug)}>
                                                    <img src={eyeIcon} alt="" className="img-fluid" />                                               
                                                </button>
                                                <button onClick={() => handleShow(data._id)}>
                                                    <img src={deleteIcon} alt="" className="img-fluid" />                                               
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : 'No record found'
                            : loadingInitial && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            }
                                {!loadingMore ? totalPages !== currentPage && <button className="btn-load-more btn btn-primary" onClick={loadMoreData}>Load More</button>
                            : <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                            <CustomModal setShow={setShow} show={show} data={data} />
                        </div>
                    </div>
                </div>
            )}
        </Main>
    )
}
