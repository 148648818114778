export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const GET_USER = "GET_USER";
export const FAIL_TO_GET_USER = "FAIL_TO_GET_USER";
export const LOADING = "LOADING"
export const PENDING = "PENDING"
export const UNATUHORIZED = "Unauthorized"
export const INVALID_CREDENTIALS = "Invalid Credentials"

export const PROFILE_PENDING = "PROFILE_PENDING"
export const PROFILE_LOADING = "PROFILE_LOADING"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_FAIL = "PROFILE_FAIL"