import React from 'react'

export const Select = ({options, setFunc, value, firstOpt, name}) => {
    return (
        <div className="form-group">
            <select onChange={(e) => setFunc(e)} name={name} value={value} className="form-select article-select mb-0">
                <option value="0" disabled={true}>{firstOpt}</option>
                {options?.map((data, key) => {
                    return <option value={data.value} key={key}>{data.label}</option>
                })}
            </select>
        </div>
    )
}
