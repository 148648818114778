import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    PENDING,
    GET_USER,
    UNATUHORIZED,
    FAIL_TO_GET_USER,
    LOADING,
    PROFILE_PENDING,
    PROFILE_SUCCESS,
    PROFILE_FAIL,
} from "./types";

import authService from "../../Services/auth.service";
import userService from "../../Services/user.service";
  
export const userLogin = (email, password) => {
  // try {
  //   const result = await authService.login({email, password});
  //   console.log(result, 'res');
  //   if (!result.status) {
  //     dispatch({
  //       type : LOGIN_FAIL,
  //       payload : { data : result }
  //     })
  //   } else {
  //     dispatch({ type: LOGIN_SUCCESS, payload: { user : result } });
  //   }
  // } catch (error) {
  //   dispatch({
  //     type: LOGIN_FAIL,
  //     payload: error
  //   });
  // }
  // return await dispatch({
  //   type : LOGIN_FAIL,
  //   payload : 'datas'
  // })
  return async (dispatch) => {
    try {
      dispatch({
        type : PENDING,
        payload : true
      })
      let data = await authService.login({email, password})
      if (data) {
        dispatch({
          type : PENDING,
          payload : false
        })
        if (!data.status) {
          dispatch({
            type : LOGIN_FAIL,
            payload : { data }
          })
        } else {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { data },
          });
        }
        return data
      }
    } catch (error) {
      
    }
  }
};

export const updateUser = (formFields) => {
  return async (dispatch) => {
    try {
      dispatch({
        type : PROFILE_PENDING,
        payload : true
      })
      let data = await userService.profileUpdate(formFields)
      if (data) {
        dispatch({
          type : PROFILE_PENDING,
          payload : false
        })
        if (!data.status) {
          dispatch({
            type : PROFILE_FAIL,
            payload : { data }
          })
        } else {
          dispatch({
            type: PROFILE_SUCCESS,
            payload: { data },
          });
        }
        return data
      }
    } catch (error) {
      
    }
  }
}

export const userLogout = () => (dispatch) => {
  authService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const getData = () => {
  return async (dispatch) => {
    dispatch({
      type : LOADING,
      payload : true
    })
    try {
      let data = await authService.getData()
      if (data) {
        dispatch({
          type : LOADING,
          payload : false
        })
        if (data?.response?.statusText === UNATUHORIZED) {
          dispatch({
            type : FAIL_TO_GET_USER,
            payload : { data }
          })
        } else {
          dispatch({
            type : GET_USER,
            payload : { data }
          })
        }
        return data
      }
    } catch (error) {
      
    }
  }
}

  // export const sendLoginRequest = () => {
  //   return {
  //     type: USER_LOGIN_REQUEST,
  //   };
  // };

  // export const userLogins = (email, password) => {
  //   return async (dispatch) => {
  //     try {
  //       // dispatch(sendLoginRequest());
  //       // const data = await axios.post(BASE_URL + "login", {email, password});
  //       // console.log(data.status);

  //       // dispatch(loginSucccess());
  //       await axios
  //         .post(BASE_URL + "login", {email, password})
  //         .then((data) => {
  //           console.log(data.status, 'asdaww');
  //           dispatch(loginSucccess());
  //         })
  //         .catch(err => {
  //           console.log("incorrect credentials");
  //           dispatch(loginFailure('incorrect credentials'));
  //         });
  //       // return true
  //     } catch(err) {
  //       dispatch(loginFailure(err.message));
  //       // return false
  //     }
  //   };
  // };