import React from 'react'
import { Main } from './Main'
import wordpress from "../assets/img/wordpress.png"
import { useNavigate } from 'react-router-dom'

export const Integration = () => {
    const navigate = useNavigate()
    return (
        <Main route="integration">
            {() => (
                <div className="content-box">
                    <div className="articles-btn-wrapper p-4">
                        <a onClick={() => navigate('/wordpress')} className='cursor-pointer'>
                            <img src={wordpress} alt="wordpress" width={250} />
                        </a>
                    </div>
                </div>
            )}
        </Main>
    )
}
