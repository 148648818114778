import React, { useState } from 'react'
import Moment from 'moment';
import { Tooltip } from 'react-tooltip';
import iconHtml from '../assets/img/icon-html.svg'
import iconCopy from '../assets/img/icon-copy.svg'
import iconGenerate from '../assets/img/icon-generate.svg'
import articleService from '../Services/article.service';

export const ViewArticle = ({
    article,
    setArticle,
    addDocument,
    articleObj
}) => {
    const [sourceFlag, setSourceFlag] = useState(false)
    const [isCopied, setIsCopied] = useState(false);
    const [rewriteLoader, setRewriteLoader] = useState(false)
    const [error, setError] = useState('')

    const copyTextToClipboard = async (text) => {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(text);
        } else {
          return document.execCommand('copy', true, text);
        }
    }

    const source = () => {
        setSourceFlag(!sourceFlag)
    }

    const CopyData = (e) => {
        e.preventDefault()
        copyTextToClipboard(article?.body)
        .then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1500);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const rewriteArticle = () => {
        setRewriteLoader(true)
        articleService.rewriteArticle({article, articleObj}).then((res) => {
            if (res?.response?.data?.error?.error) {
                setError(res?.response?.data?.error?.error.message)
            } else {
                setArticle(res)
                addDocument(res);
            }
            setRewriteLoader(false)
        }).catch((err) => {
            console.log(err, 'err');
            setRewriteLoader(false)
        })
    }

    const closeErrorDiv = () => {
        setError('')
    }

    return (
        <div className="col-lg-7 col-xxl-8">
            {error !== '' ? (
                <div className="article-right-content article-result-content">
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        {error}
                        <button type="button" className="close alert-close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true" onClick={closeErrorDiv}>&times;</span>
                        </button>
                    </div>
                </div>
            ) : ''}
            {rewriteLoader ? (
                <div className="article-right-content article-result-content text-center">
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                </div>
            ) : ''}
            {!rewriteLoader && article ?
                <div className="article-right-content article-result-content">
                    {article?.imagePath !== '' ? (
                        <div className="feature-image">
                            <img src={article?.imagePath} alt="" className="img-fluid" />
                        </div>
                    ) : ''}
                    <div className="article-text-box">
                        <div className="title-wraper">
                            <h3>{article.title}</h3>
                            <div className="article-btns-group">
                                <p className="created-date">Created : {Moment(article?.created_at).format("DD, MMM yy")}</p>
                                <button className="btn-copy" data-tooltip-id="html-tooltip" onClick={source}><img src={iconHtml} alt="" className="img-fluid" /></button>
                                <button className="btn-copy" data-tooltip-id="copy-tooltip" onClick={(e) => CopyData(e)}><img src={iconCopy} alt=""className="img-fluid" /></button>
                                <button className="btn-generate" data-tooltip-id="generate-tooltip" onClick={rewriteArticle}><img src={iconGenerate} alt=""className="img-fluid" /></button>
                            </div>
                        </div>
                        <Tooltip
                            id="copy-tooltip"
                            content={isCopied ? 'Copied!' : 'Copy'}
                            events={['hover']}
                        />
                        <Tooltip
                            id="generate-tooltip"
                            content="Re-write"
                            events={['hover']}
                        />
                        <Tooltip
                            id="html-tooltip"
                            content="source"
                            events={['hover']}
                        />
                        <div className="scroller">
                            {!sourceFlag ? <p dangerouslySetInnerHTML={{ __html: article?.body }}></p>
                            : <p>{article?.body}</p> }
                            {article?.metaTitle ? <p><b>Meta Title:</b> {article?.metaTitle}</p> : ''}
                            {article?.metaDescription ? <p><b>Meta Description:</b> {article?.metaDescription}</p> : ''}
                        </div>
                    </div>
                </div>
            : ''}
        </div>
    )
}
