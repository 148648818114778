import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import editIcon from '../assets/img/edit.svg'
import trashIcon from '../assets/img/trash.svg'
import { ArticleListing } from './ArticleListing';
import { ViewArticle } from './ViewArticle';
import articleService from '../Services/article.service';
import { toast } from 'react-toastify';
import { CustomModal } from './CustomModal';
import { Main } from './Main';

export const SubArticles = () => {
    const { slug } = useParams()
    const [newTitle, setNewTitle] = useState('')
    const [article, setArticle] = useState('')
    const [project, setProject] = useState('')
    const [loadingInitial, setLoadingInitial] = useState(true)
    const [loadingMore, setLoadingMore] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const [documents, setDocuments] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [text, setText] = useState('');
    const [editFlag, setEditFlag] = useState(false)
    const [titleFlag, setTitleFlag] = useState(false)
    const [show, setShow] = useState(false);
    const [data, setData] = useState({})
    const navigate = useNavigate()
    useEffect(() => {
        let onChangeSearch = setTimeout( async () => {
            setLoadingInitial(true)
            await articleService.getSubArticles('batch', currentPage, text, slug).then((res) => {
                setLoadingInitial(false);
                setProject(res.data.article)
                setNewTitle(res.data.article.title)
                if (currentPage === 1) {
                    setDocuments(res.data.paginator.data)
                    setIsLoading(true)
                } else {
                    setDocuments([...documents, ...res.data.paginator.data])
                }
                if (loadingMore) {
                    setLoadingMore(false);
                }
                setTotalPages(res.data.paginator.last_page);
            }).catch((err) => {
                setLoadingInitial(false);
                if (loadingMore) {
                    setLoadingMore(false);
                }
                console.log(err);
            })
        }, 1000)
        return () => clearTimeout(onChangeSearch)
    }, [currentPage, text, reload])

    const loadMoreData = async () => {
        setLoadingMore(true)
        setCurrentPage(currentPage + 1);
    }

    const viewArticle = (id) => {
        let article = documents.filter((docs) => {
            return docs.id === id
        })
        setArticle(article[0])
    }

    const onChangeSearch = (e) => {
        setText(e.target.value)
        setTimeout(() => {
            setCurrentPage(1)
            setDocuments([])
            setIsLoading(false)
            setTotalPages(1)
        }, 1500)
    }

    const editTitle = () => {
        setEditFlag(true)
    }

    const cancel = () => {
        setEditFlag(false)
        setNewTitle(project.title)
    }

    const saveTitle = async () => {
        setTitleFlag(true)
        let data = {
            id : project._id,
            title : newTitle
        }
        await articleService.titleUpdate(data).then((data) => {
            if (data?.status) {
                toast.success(data?.message)
                setNewTitle(data.data.title)
                setTimeout(() => {
                    window.location.pathname = `/sub_articles/${data.data.slug}`
                }, 3500)
            } else {
                toast.error(data.response.data.errors.title[0])
                setNewTitle(project.title)
            }
            setEditFlag(false)
            setTitleFlag(false)
        }).catch((err) => {
            console.log(err);
        })
    }

    const addDocument = (newDocument) => {
        let objIndex = documents.findIndex(obj => obj.id === newDocument.id)
        if (objIndex !== -1) {
            documents[objIndex] = newDocument
        }
    };

    const handleShow = () => {
        setShow(true)
        setData({parentId : project._id, type : 'project'})
    }

    return (
        <Main route="batch_article_list">
            {() => (
                <>
                    <div className="row mt-4">
                        <div className="col-lg-5 col-xxl-4">
                            <div className="article-left-side documents mt-3">
                                <div className="crypto-box-wraper">
                                    <div className="title-wraper">
                                        <h2>My Documents</h2>
                                        <ul className="documents-tabs-links">
                                            <li>
                                            <a onClick={() => navigate('/single_article_list')} className='cursor-pointer'>Single</a>
                                            </li>
                                            <li>
                                            <a onClick={() => navigate('/bulk_article_list')} className="cursor-pointer active">Bulk</a>
                                            </li>
                                        </ul>
                                    </div>
                                    {project ?
                                    <>
                                        <div className="title-wraper">
                                            {editFlag ? 
                                                <>
                                                    <input type="text" className='form-control me-3' value={newTitle} onChange={(e) => setNewTitle(e.target.value)} />
                                                    {!titleFlag ?
                                                        <>
                                                            <button className='btn btn-primary me-3' onClick={saveTitle}>Save</button>
                                                            <button className='btn btn-secondary' onClick={cancel}>Cancel</button>
                                                        </>
                                                    : <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                                </>
                                            : <h2>{newTitle}</h2>}
                                            {!editFlag ? 
                                                <div className="btns-group-crypto">
                                                    <button className="btn-edit" onClick={editTitle}><img src={editIcon} alt="" className="img-fluid" /></button>
                                                    <button className="btn-delete" onClick={handleShow}><img src={trashIcon} alt="" className="img-fluid" /></button>
                                                </div>
                                            : ''}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 p-0">
                                                <div className="crypto-box">
                                                    <h3>Language</h3>
                                                    <p>{project.language}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4  p-0">
                                                <div className="crypto-box">
                                                    <h3>Tone of Voice</h3>
                                                    <p>{project.tone}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4  p-0">
                                                <div className="crypto-box">
                                                    <h3>Word Count</h3>
                                                    <p>{project.wordCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </> : loadingInitial ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : 'No article found'
                                    }
                                </div>
                                {project ?
                                <ArticleListing 
                                    documents={documents}
                                    isLoading={isLoading}
                                    loadingInitial={loadingInitial}
                                    loadingMore={loadingMore}
                                    loadMoreData={loadMoreData}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    viewArticle={viewArticle}
                                    onChangeSearch={onChangeSearch}
                                    type="batch"
                                    setCurrentPage={setCurrentPage}
                                    setDocuments={setDocuments}
                                    setIsLoading={setIsLoading}
                                    setTotalPages={setTotalPages}
                                    parentId={project._id}
                                    setReload={setReload}
                                    reload={reload}
                                    setArticle={setArticle}
                                /> : ''}
                            </div>
                        </div>
                        <ViewArticle
                            article={article}
                            setArticle={setArticle}
                            addDocument={addDocument}
                            articleObj={project}
                        />
                    </div>
                    <CustomModal setShow={setShow} show={show} data={data} />
                </>
            )}
        </Main>
    )
}
