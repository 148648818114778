import React, { useState } from 'react'
import { Main } from './Main'
import userService from '../Services/user.service'
import { toast } from 'react-toastify'

export const ChangePassword = () => {
    const [changeInput, setInputChange] = useState(false)
    const [flag, setflag] = useState(false)
    const [errors, setErrors] = useState({})
    const [formFields, setFormFields] = useState({
        old_password : '',
        new_password: '',
    })

    const handleInputChange = (e) => {
        setInputChange(true)
        setFormFields((state) => ({
            ...state,
            [e.target.name] : e.target.value
        }))
    }

    const ChangePassword = (e) => {
        e.preventDefault()
        setflag(true)
        userService.changePassword(formFields).then((data) => {
            if (data.response) {
                if (Object.keys(data.response.data.errors).length) {
                    setErrors(data.response.data.errors)
                } else {
                    setErrors({old_password : data.response.data.message})
                }
            } else {
                toast.success(data.message)
                setErrors({})
                setFormFields({
                    old_password : '',
                    new_password: '',
                })
            }
            setflag(false)
        }).catch((err) => {
            setErrors(err.response.data.errors)
            setflag(false)
        })
    }
    return (
        <Main route="profile">
          {() => (
              <div className="container-fluid h-custom">
                <form className="row col-md-6 article-input-box mt-4">
                  <div className='col-md-12 col-lg-12 col-xl-12'>
                    <div className="form-outline mb-4 title-input-box">
                        <label className="form-label">Old Password</label>
                        <input type="password" id="old_password" name='old_password' className="form-control"
                        onChange={(e) => {handleInputChange(e)}}
                        value={formFields.old_password}
                        placeholder="Enter old password" />
                        <p className='text-danger'>{errors?.['old_password']}</p>
                    </div>
                    <div className="form-outline mb-4 title-input-box">
                        <label className="form-label">New Password</label>
                        <input type="password" id="new_password" name='new_password' className="form-control"
                        onChange={(e) => {handleInputChange(e)}}
                        value={formFields.new_password}
                        placeholder="Enter new password" />
                        <p className='text-danger'>{errors?.['new_password']}</p>
                    </div>
                  </div>
                  <div className="form-outline">
                    {flag ? (
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        </button>
                    ) : <button type="button" onClick={(e) => ChangePassword(e)} className={`btn btn-primary${!changeInput ? ' disabled' : ''}`}>
                            Change Password
                        </button>
                    }
                  </div>
                </form>
              </div>
          )}
        </Main>
    )
}
