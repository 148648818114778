import React, { useState } from 'react'
import Moment from 'moment';
import eyeIcon from '../assets/img/eye.svg'
import deleteIcon from '../assets/img/delete.svg'
import { CustomModal } from './CustomModal';
import { toast } from 'react-toastify';
import articleService from '../Services/article.service';

export const ArticleListing = ({
    documents,
    isLoading,
    loadingInitial,
    loadingMore,
    loadMoreData,
    totalPages,
    currentPage,
    viewArticle,
    onChangeSearch,
    type,
    setCurrentPage,
    setDocuments,
    setIsLoading,
    setTotalPages,
    parentId,
    setReload,
    reload,
    setArticle
    }) => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState({});
    const [checkboxes, setCheckboxes] = useState([])
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [postSelectedFlag, setPostSelectedFlag] = useState(false)

    const handleShow = (id) => {
        setShow(true)
        if (parentId !== '') {
            setData({
                parentId,
                id,
                type,
                setCurrentPage,
                setDocuments,
                setIsLoading,
                setTotalPages,
                setReload,
                reload,
                setArticle
            })
        } else {
            setData({
                parentId : id,
                type,
                setCurrentPage,
                setDocuments,
                setIsLoading,
                setTotalPages,
                setReload,
                reload,
                setArticle
            })
        }
    }

    const handleCheckbox = (e) => {
        let checked = e.target.checked
        let value = e.target.value
        if (checked) {
            setCheckboxes([...checkboxes, value])
        } else {
            setCheckboxes(checkboxes.filter((data) => data !== value))
        }
    }

    const bulkDelete = async (e) => {
        if (checkboxes.length >= 1) {
            setBtnDisabled(true)
            await articleService.bulkDelete({ids : checkboxes}).then((res) => {
                setBtnDisabled(false)
                setCurrentPage(1)
                setDocuments([])
                setIsLoading(false)
                setTotalPages(1)
                setReload(!reload)
                setArticle('')
                toast.success(res?.message)
            }).catch((err) => {
                setBtnDisabled(false)
                console.log(err);
            })
        } else {
            toast.error('Select atleast one article')
        }
    }

    const postAll = async () => {
        setShow(true)
        setData({
            type : 'all',
            projectType : type,
            parentId : parentId,
            modalBody : 'Are you sure you want to post all articles to wordpress?'
        })
    }

    const postSelectedToWp = async (e) => {
        if (checkboxes.length >= 1) {
            setPostSelectedFlag(true)
            let data = {
                ids : checkboxes,
                type : 'selected',
                projectType : type,
                parentId : parentId
            }
            await articleService.postToWordpress(data).then((res) => {
                setPostSelectedFlag(false)
                if (res?.code === 200) {
                    toast.success(res?.message)
                } else {
                    toast.error(res?.message)
                }
            }).catch((err) => {
                setPostSelectedFlag(false)
                console.log(err);
            })
        } else {
            toast.error('Select atleast one article')
        }
    }

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollRatio = scrollTop / (scrollHeight - clientHeight);
        if (scrollRatio >= 1) {
            if (totalPages !== currentPage) {
                loadMoreData()
            }
        }
    };

    return (
        <div>
            <div className="bulk-article-search">
                <h3>{type[0].toUpperCase() + type.slice(1)} Article List</h3>
                <div className="search-wraper">
                    <form action="">
                        <button><i className="fa-solid fa-magnifying-glass"></i></button>
                        <input type="search" onChange={(e) => {onChangeSearch(e)}} className="form-control" placeholder="Search here..." />
                    </form>
                </div>
            </div>
            <div className='mb-3 d-flex justify-content-between'>
                { !postSelectedFlag ? <button className='btn btn-primary' onClick={(e) => postSelectedToWp(e)}>Post to Wordpress</button>
                : <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                <button className='btn btn-primary' onClick={postAll}>Post All to Wordpress</button>
                { !btnDisabled ? <button onClick={(e) => bulkDelete(e)}>
                    <img src={deleteIcon} alt="" className="img-fluid" />                                               
                </button> :
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                }
            </div>
            <div className='scroller' onScroll={(e) => handleScroll(e)}>
                {isLoading ? 
                    documents.length ? 
                    documents.map((data, index) => {
                        return (
                            <div className="article-input-detail article-input-box" key={index}>
                                <div className="sec-title-box">
                                    <div><input type="checkbox" onClick={(e) => handleCheckbox(e)} value={type === 'batch' ? data.id : data._id} /></div>
                                    <div className="title-with-date">
                                        <p>{Moment(data.created_at).format("DD, MMM yy")}</p>
                                        <h2>{data.title}</h2>
                                        <p>{data.status}</p>
                                        <p>{data?.postToWordpress === 'posted' ? 'Posted to wordpress' : ''}</p>
                                    </div>
                                    <div className="btns-group">
                                        <button onClick={() => viewArticle(type === 'batch' ? data.id : data._id)}>
                                            <img src={eyeIcon} alt="" className="img-fluid" />                                               
                                        </button>
                                        {/* {type === 'batch' ?
                                            <button>
                                                <img src={iconGenerate} alt="" className="img-fluid" />                                               
                                            </button>
                                        : ''} */}
                                        <button onClick={() => handleShow(type === 'batch' ? data.id['$oid'] : data._id)}>
                                            <img src={deleteIcon} alt="" className="img-fluid" />                                               
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : 'No record found'
                : loadingInitial && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                }
                {!loadingMore ? totalPages !== currentPage && <button className="btn-load-more btn btn-primary d-none" onClick={loadMoreData}>Load More</button>
                : <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
            </div>
            <CustomModal setShow={setShow} show={show} data={data} />
        </div>
    )
}
