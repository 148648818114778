import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ArticleListing } from './ArticleListing';
import { ViewArticle } from './ViewArticle';
import articleService from '../Services/article.service';
import { Main } from './Main';
import { useDispatch } from 'react-redux';
import { LOGIN_FAIL } from './actions/types';

export const SingleArticleListing = () => {
    const [article, setArticle] = useState('')
    const [loadingInitial, setLoadingInitial] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const [documents, setDocuments] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [text, setText] = useState('');
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        let onChangeSearch = setTimeout( async () => {
            setLoadingInitial(true);
            await articleService.getDocs('single', currentPage, text).then((res) => {
                if (res?.response?.data?.message === 'Unauthenticated.') {
                    dispatch({
                        type : LOGIN_FAIL,
                        payload : res?.response
                    })
                    navigate('/')
                } else {
                    setLoadingInitial(false);
                    if (currentPage === 1) {
                        setDocuments(res.data.data)
                        setIsLoading(true)
                    } else {
                        setDocuments([...documents, ...res.data.data])
                    }
                    if (loadingMore) {
                        setLoadingMore(false);
                    }
                    setTotalPages(res.data.last_page);
                }
            }).catch((err) => {
                setLoadingInitial(false);
                if (loadingMore) {
                    setLoadingMore(false);
                }
                console.log(err);
            })
        }, 1000)
        return () => clearTimeout(onChangeSearch)
    }, [currentPage, text, reload])

    const loadMoreData = async () => {
        setLoadingMore(true)
        setCurrentPage(currentPage + 1);
    }

    const viewArticle = (id) => {
        let article = documents.filter((docs) => {
            return docs._id === id
        })
        setArticle(article[0])
    }

    const addDocument = (newDocument) => {
        let objIndex = documents.findIndex(obj => obj._id === newDocument._id)
        if (objIndex !== -1) {
            documents[objIndex] = newDocument
        }
    };

    const onChangeSearch = (e) => {
        setText(e.target.value)
        setTimeout(() => {
            setCurrentPage(1)
            setDocuments([])
            setIsLoading(false)
            setTotalPages(1)
        }, 1500)
    }

    return (
        <Main route="single_article_list">
            {() => (
                <div className="row mt-4">
                    <div className="col-lg-5 col-xxl-4">
                        <div className="article-left-side documents mt-3">
                            <div className="crypto-box-wraper">
                                <div className="title-wraper">
                                    <h2>My Documents</h2>
                                    <ul className="documents-tabs-links">
                                        <li>
                                        <a className="cursor-pointer active">Single</a>
                                        </li>
                                        <li>
                                        <a className='cursor-pointer' onClick={() => navigate('/bulk_article_list')}>Bulk</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ArticleListing 
                                documents={documents}
                                isLoading={isLoading}
                                loadingInitial={loadingInitial}
                                loadingMore={loadingMore}
                                loadMoreData={loadMoreData}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                viewArticle={viewArticle}
                                onChangeSearch={onChangeSearch}
                                type="single"
                                setCurrentPage={setCurrentPage}
                                setDocuments={setDocuments}
                                setIsLoading={setIsLoading}
                                setTotalPages={setTotalPages}
                                parentId=''
                                setReload={setReload}
                                reload={reload}
                                setArticle={setArticle}
                            />
                        </div>
                    </div>
                    <ViewArticle
                        article={article}
                        setArticle={setArticle}
                        addDocument={addDocument}
                        articleObj=''
                    />
                </div>
            )}
        </Main>
    )
}
