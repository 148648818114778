import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    PENDING,
    UNATUHORIZED,
    GET_USER,
    FAIL_TO_GET_USER,
    LOADING,
    PROFILE_PENDING,
    PROFILE_SUCCESS,
    PROFILE_FAIL,
  } from "../actions/types";
  
  const user = JSON.parse(localStorage.getItem("user"));

  const initialState = user
    ? { isLoggedIn: true, user, errorMessage : null, loading : false }
    : { isLoggedIn: false, user: null, errorMessage : null, loading : false };

  const auth = (state = initialState, action) => {
    // state = state || await abcd();
    const { type, payload } = action;

    // state.then((data) => {
    //   console.log(data, 'ssasdawd');
    //   return {
    //     ...data,
    //     isLoggedIn: true,
    //     user: payload.data,
    //     errorMessage : null
    //   };
    // }).catch((err) => {

    // })

    switch (type) {
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.data,
          errorMessage : null,
          loading : false
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn : false,
          user : null,
          errorMessage : payload.data.message,
          loading : false
        }
      case UNATUHORIZED:
        return {
          ...state,
          isLoggedIn : false,
          user : null,
          errorMessage : payload,
          loading : false
        }
      case PENDING:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          errorMessage : null,
          loading : payload
        };
      case PROFILE_PENDING:
        return {
          ...state,
          isLoggedIn: true,
          user: user,
          errorMessage : null,
          profileLoading : payload
        };
      case PROFILE_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.data,
          errorMessage : null,
          profileLoading : false
        };
      case PROFILE_FAIL:
        return {
          ...state,
          isLoggedIn: true,
          user: user,
          errorMessage : null,
          profileLoading : false
        };
      case GET_USER:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.data,
          errorMessage : null,
          loading : false
        };
      case FAIL_TO_GET_USER:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          errorMessage : null,
          loading : false
        }
      case LOADING:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          errorMessage : null,
          loading : payload
        }
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          errorMessage : null,
          loading : false
        };
      default:
        return state;
    }
  }
export default auth