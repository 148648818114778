import React, { useEffect, useState } from 'react'
import isEmail from 'validator/lib/isEmail';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from './actions/auth';

export const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [flag, setFlag] = useState(false)
    const { isLoggedIn, loading } = useSelector((state) => state.auth);
    const [formFields, setFormFields] = useState({
        email : '',
        password : ''
    })
    const [errors, setErrors] = useState({})
    useEffect(() => {
        if (isLoggedIn) {
            navigate('/dashboard')
        } else if (!isLoggedIn) {
            setFlag(true)
        }
    }, [])
    const handleInputChange = (e) => {
        setFormFields((state) => ({
            ...state,
            [e.target.name] : e.target.value
        }))
    }
    const formSubmit = async () => {
        let formErrors = {}
        if (formFields.email === '' || formFields.password === '') {
            formErrors['error'] = 'All fields are required'
        } else if (!isEmail(formFields.email)) {
            formErrors['error'] = 'Invalid email'
        }
        setErrors(formErrors)
        if (Object.keys(formErrors).length === 0) {
            try {
                dispatch(userLogin(formFields.email, formFields.password)).then((data) => {
                    if (data.status) {
                        navigate('/dashboard', { replace : true })
                    } else if (data?.response?.status == 422) {
                        setErrors(data.response.data.errors)
                    } else if (!data.status) {
                        setErrors({error : data.message})
                    }
                })
            } catch (error) {
                console.log(error, 'err');
            }
        }
    }
  return (
    <div>
        {!flag ? '' : 
        <section className="vh-100 pt-4">
            <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-md-9 col-lg-6 col-xl-5">
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                    className="img-fluid" alt="Sample image" />
                </div>
                <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                    <div>
                        { Object.values(errors).map((data, key) => {
                            return (
                                <div className='alert alert-danger error-alert alert-dismissible fade show' key={key}>{data}</div>
                            )
                        })}
                    </div>
                    <form>
                    <div className="form-outline mb-4">
                        <label className="form-label">Email address</label>
                        <input type="email" id="email" name='email' className="form-control form-control-lg"
                        onChange={(e) => handleInputChange(e)}
                        value={formFields.email}
                        placeholder="Enter a valid email address" />
                        {/* {errors['email']} */}
                    </div>

                    <div className="form-outline mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" id="password" name='password' className="form-control form-control-lg"
                        onChange={(e) => handleInputChange(e)}
                        value={formFields.password}
                        placeholder="Enter password" />
                        {/* {errors['password']} */}
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                        <a href="#!" className="text-body">Forgot password?</a>
                    </div>

                    <div className="text-center text-lg-start mt-4 pt-2">
                        {!loading ? 
                            <button type="button" className="btn btn-primary btn-lg"
                            onClick={formSubmit}
                            >Login</button>
                        :
                            <span className="spinner-border text-primary spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        }
                        <p className="small fw-bold mt-2 pt-1 mb-0">Don't have an account?
                            <a className="link-danger cursor-pointer" onClick={() => navigate('/registeration')}>
                                Register
                            </a>
                        </p>
                    </div>
                    </form>
                </div>
                </div>
            </div>
        </section>
        }
    </div>
  )
}
