import React, { useState } from 'react'
import authService from '../Services/auth.service'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export const Registeration = () => {
    const [errors, setErrors] = useState({})
    const [flag, setFlag] = useState(false)
    const navigate = useNavigate()
    const [formFields, setFormFields] = useState({
        first_name : '',
        last_name: '',
        email : '',
        phone : '',
        password : ''
    })
    const handleInputChange = (e) => {
        setFormFields((state) => ({
            ...state,
            [e.target.name] : e.target.value
        }))
    }
    const formSubmit = (e) => {
        e.preventDefault()
        setFlag(true)
        authService.register(formFields).then((res) => {
            setFlag(false)
            console.log(res,'res');
            // let arr = Object.keys(formFields)
            // arr.map((data) => {
            //     formErrors[data] = res.response.data.errors[data]
            // })
            if (res.response) {
                setErrors(res.response.data.errors)
            } else {
                setFormFields({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    password: ''
                });
                toast.success(res)
                setErrors({})
            }
        }).catch((err) => {
            setFlag(false)
            console.log(err, 'eerrr');
        })
    }
  return (
    <section className="vh-100 pt-4">
        <div className="container-fluid h-custom">
            <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-9 col-lg-6 col-xl-5">
                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                className="img-fluid" alt="Sample image" />
            </div>
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                <form>
                    <div className="form-outline mb-4">
                        <label className="form-label">First Name</label>
                        <input type="text" id="first_name" name='first_name' className="form-control form-control-lg"
                        onChange={(e) => {handleInputChange(e)}}
                        value={formFields.first_name}
                        placeholder="Enter a valid first name" />
                        <p className='text-danger'>{errors['first_name']}</p>
                    </div>
                    <div className="form-outline mb-4">
                        <label className="form-label">Last Name</label>
                        <input type="text" id="last_name" name='last_name' className="form-control form-control-lg"
                        onChange={(e) => {handleInputChange(e)}}
                        value={formFields.last_name}
                        placeholder="Enter a valid last name" />
                        <p className='text-danger'>{errors['last_name']}</p>
                    </div>
                    <div className="form-outline mb-4">
                        <label className="form-label">Email address</label>
                        <input type="email" id="email" name='email' className="form-control form-control-lg"
                        onChange={(e) => {handleInputChange(e)}}
                        value={formFields.email}
                        placeholder="Enter a valid email address" />
                        <p className='text-danger'>{errors['email']}</p>
                    </div>
                    <div className="form-outline mb-4">
                        <label className="form-label">Phone</label>
                        <input type="text" id="phone" name='phone' className="form-control form-control-lg"
                        onChange={(e) => {handleInputChange(e)}}
                        value={formFields.phone}
                        placeholder="Enter a valid phone" />
                        <p className='text-danger'>{errors['phone']}</p>
                    </div>
                    <div className="form-outline mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" id="password" name='password' className="form-control form-control-lg"
                        onChange={(e) => {handleInputChange(e)}}
                        value={formFields.password}
                        placeholder="Enter password" />
                        <p className='text-danger'>{errors['password']}</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <a href="#!" className="text-body">Forgot password?</a>
                    </div>
                    <div className="text-center text-lg-start mt-4 pt-2">
                        {!flag ? 
                            <button onClick={(e) => {formSubmit(e)}} type="button" className="btn btn-primary btn-lg"
                            >Register</button>
                        :
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only"></span>
                            </div>
                        }
                        <p className="small fw-bold mt-2 pt-1 mb-0">Already have an account?
                            <a className="link-primary cursor-pointer" onClick={() => navigate('/')}>
                                Login
                            </a>
                        </p>
                    </div>
                </form>
            </div>
            </div>
        </div>
    </section>
  )
}
