import axios from "axios";
import { BASE_URL } from "../Constant";

const register = async (data) => {
  return axios.post(BASE_URL + "register", data).then((response) => {
    if (response.data.status) {
        return response.data.message
    } else {
        return 'Something went wrong'
    }
  }).catch((err) => {
      return err
  });
};

const login = async (data) => {
  try {
    let result = await axios.post(BASE_URL + "login", data)
    if (result.data.status) {
      localStorage.setItem("user", JSON.stringify(result.data));
      localStorage.setItem(`wordpress ${result.data.data._id}`, JSON.stringify(result.data.data.integration));
    }
    return result.data
  } catch (error) {
    return error
  }
  // return await axios
  //   .post(BASE_URL + "login", data).then((response) => {
  //     // if (response.data.status) {
  //     //   localStorage.setItem("user", JSON.stringify(response.data));
  //     // } else {
  //     //   toast.error(response.data.message);
  //     // }
  //     if (response.data.status) {
  //       // toast.success('User registered successfully')
  //       // toast.success(response.data.message)
  //       localStorage.setItem("user", JSON.stringify(response.data));
  //     }
  //     return response.data
  //   }).catch((err) => {
  //     return err
  //   });
};

const logout = () => {
  let userId = JSON.parse(localStorage.getItem('user'))?.data?._id
  localStorage.removeItem(`wordpress ${userId}`)
  localStorage.removeItem("user");
};

const obj = {
  register,
  login,
  logout,
}

export default obj