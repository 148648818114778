import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import '../assets/css/transitionWrapper.css'; // Custom styles for transitions

const TransitionWrapper = ({ children }) => {
  const location = useLocation();

  return (
    <SwitchTransition component={null}>
      <CSSTransition
        key={location.pathname}
        timeout={400}
        classNames="fade"
        unmountOnExit
      >
        <div className="transition-wrapper">
          {children}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default TransitionWrapper;