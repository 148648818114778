import axios from "axios"
import { BASE_URL } from "../Constant"
import authHeader from "./auth-header"

const profileUpdate = async (data) => {
    try {
        let result = await axios.post(BASE_URL + "profile-update", data, { headers: authHeader() })
        return result.data
    } catch (error) {
        return error
    }
}

const getData = async () => {
    try {
        let result = await axios.get(BASE_URL + "get-data", { headers: authHeader() })
        return result.data
    } catch (error) {
        return error
    }
}

const changePassword = async (data) => {
    try {
        let result = await axios.post(BASE_URL + "change-password", data, { headers: authHeader() })
        return result.data
    } catch (error) {
        return error
    }
}

const wordpressCredentials = async (data) => {
    try {
        let result = await axios.post(BASE_URL + "wordpress-credentials", data, { headers: authHeader() })
        return result.data
    } catch (error) {
        return error
    }
}

const obj = {
    profileUpdate,
    getData,
    changePassword,
    wordpressCredentials
}

export default obj