// const BASE_URL = 'http://127.0.0.1:8000/api/'
// const BASE_URL = 'http://localhost/inksta.ai/public/api/'

// const BASE_URL = 'http://inksta.ai.test/api/'
// const NODE_BASE_URL = 'http://localhost:3001/api/'
const BASE_URL = process.env.REACT_APP_BASE_URL
const NODE_BASE_URL = process.env.REACT_APP_NODE_BASE_URL

export {
    BASE_URL,
    NODE_BASE_URL
}